<template>
  <div>
    <!-- Dialog Idea Settings -->
    <dialog-idea-settings
      :visible="dialogIdeaSettingsVisible"
      :category="$store.state.idea.catalogueCategory"
      :idea="$store.state.idea.catalogueIdea || {}"
      :update-idea-settings="true"
      @close-dialog="onCloseDialogIdeaSettings"
      @idea-saved="onCloseDialogIdeaSettings"
    />
    <!-- /Dialog Idea Settings -->
  </div>
</template>

<script>
import DialogIdeaSettings from '@/views/SelectIdea/Dialogs/DialogIdeaSettings.vue'

import { mapMutations, mapState } from 'vuex'

export default {
  name: 'IdeaSettingsPage',

  components: { DialogIdeaSettings },

  computed: {
    ...mapState('ui', ['dialogs']),

    dialogIdeaSettingsVisible () {
      return true
    }
  },

  methods: {
    ...mapMutations('ui', ['setDialogIdeaSettingsState']),

    onCloseDialogIdeaSettings () {
      this.setDialogIdeaSettingsState(false)
    }
  }
}
</script>
